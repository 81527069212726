<!-- 对账资料弹窗 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'对账资料':'对账资料'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <div style="border-bottom: 1px solid #eeeeee;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">开票信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="客户名称：" style="margin-bottom: 0!important;">
              <span>同城救援</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开票税号：" style="margin-bottom: 0!important;">
              <span>91252252221</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="电话号码：" style="margin-bottom: 0!important;">
              <span>4000929555</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户银行：" style="margin-bottom: 0!important;">
              <span>深圳市衣村商业银行</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="银行账号：" style="margin-bottom: 0!important;">
              <span>456456415215</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业地址：" style="margin-bottom: 0!important;">
              <span>深圳市汉海达大厦</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="邮寄地址：" style="margin-bottom: 0!important;">
              深圳市钟多街道何家坝路261号汉海达大厦8-14
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">收款信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="开户行：" style="margin-bottom: 0!important;">
              <span>深圳衣村商业银行潭头支行</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户名：" style="margin-bottom: 0!important;">
              <span>同城救援</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="账号：" style="margin-bottom: 0!important;">
              <span>4000929555</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票：" style="margin-bottom: 0!important;">
              <div style="display: flex;align-items: center;">
                <span style="color: #FF9B05;">123123123152</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="付款凭证：" style="margin-bottom: 0!important;">
              <div style="display: flex;align-items: center;">
                <span style="color: #FF9B05;">123123123152</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
